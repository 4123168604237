

import "./filterscontrol.scss"

const Filterscontrol = () => {
   const a = false
   if (a) {
      return (
         <div className="filterscontrol">Filterscontrol</div>
      )
   }
}

export default Filterscontrol