

import Header from "../../components/header/Header"
import "./rewards.scss"

const Rewards = () => {
   return (
      <div className="rewards">
         <Header />
         <div class="rewards__wrapper">
            <div class="rewards__colum"></div>
            
         </div>
         
      </div>
   )
}

export default Rewards

// rewards from header menu "shop travel"